import React, { useState, useCallback, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import './CheckoutForm.css'; // Assurez-vous que ce fichier existe pour ajouter du style

const CheckoutForm = ({ total, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false); // État pour gérer l'indicateur de chargement
  const navigate = useNavigate(); // Hook pour navigation
  const [paymentError, setPaymentError] = useState(null); // Gérer les erreurs de paiement

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js n'est pas encore chargé
    }

    const cardElement = elements.getElement(CardElement);

    setIsProcessing(true);
    setPaymentError(null); // Réinitialisation des erreurs

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        console.error('Erreur lors du paiement :', error.message);
        setPaymentError(error.message); // Afficher l'erreur
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log('Paiement réussi !');
        // Transmettre les articles et le total global à la page de confirmation
        navigate('/confirmation', {
          state: {
            paymentIntent,
            articles: JSON.parse(localStorage.getItem('articles')), // Tous les articles regroupés
            total, // Total global
          },
        });
      } else {
        setPaymentError('Le paiement n\'a pas pu être effectué.'); // Si le statut n'est pas "succeeded"
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation du paiement :', error);
      setPaymentError('Une erreur s\'est produite lors du traitement du paiement.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentRequest = useCallback(async () => {
    if (!stripe || !elements) return;
  
    // Initialisation de la requête de paiement
    const paymentRequest = stripe.paymentRequest({
      country: 'FR', // Pays de l'utilisateur
      currency: 'eur', // Devise
      total: {
        label: 'Total à payer',
        amount: total * 100, // Montant total en centimes
      },
      requestPayerName: true, // Demander le nom de l'utilisateur
      requestPayerEmail: true, // Demander l'email
    });
  
    // Vérifie si l'utilisateur peut utiliser Apple Pay ou Google Pay
    const result = await paymentRequest.canMakePayment();
    if (result) {
      // Monte le bouton uniquement si Apple Pay ou Google Pay est disponible
      const paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest,
      });
  
      paymentRequestButton.mount('#payment-request-button');
  
      // Gestion des événements lors du paiement avec Payment Request
      paymentRequest.on('paymentmethod', async (event) => {
        try {
          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: event.paymentMethod.id,
          });
  
          if (error) {
            console.error('Erreur lors du paiement avec Apple Pay/Google Pay :', error.message);
            event.complete('fail');
          } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            console.log('Paiement réussi avec Apple Pay ou Google Pay !');
            event.complete('success');
            navigate('/confirmation', { state: { paymentIntent } });
          } else {
            console.error('Le paiement n’a pas pu être finalisé.');
            event.complete('fail');
          }
        } catch (err) {
          console.error('Erreur lors de la confirmation du paiement :', err.message);
          event.complete('fail');
        }
      });
    }
  }, [stripe, elements, total, clientSecret, navigate]);  
  
  useEffect(() => {
    if (stripe) {
      handlePaymentRequest();
    }
  }, [stripe, handlePaymentRequest]);

  return (
    <form onSubmit={handleSubmit}>
      <div id="payment-request-button"></div>
      <div className="card-element-container">
        <CardElement options={{ hidePostalCode: true }} />
      </div>

      <button type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? 'Paiement en cours...' : `Payer ${total.toFixed(2)} €`}
      </button>

      {/* Afficher le bouton de paiement Stripe ici */}
      <div id="payment-request-button"></div>

      {/* Afficher le spinner de chargement pendant le traitement */}
      {isProcessing && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}

      {/* Afficher l'erreur de paiement si elle existe */}
      {paymentError && <p className="error-message">{paymentError}</p>}
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </form>
  );
};

export default CheckoutForm;
