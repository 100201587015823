import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale } from 'chart.js';
import './Statistics.css';

// Enregistrement des éléments de Chart.js pour les graphiques
ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale);

const Statistics = () => {
  const [statistics, setStatistics] = useState({
    dishPercentages: {},
    mostOrderedDish: '',
    hourlyOrders: [],
    dailyRevenue: 0,
    weeklyRevenue: 0,
    monthlyRevenue: 0,
    averageDailyRevenue: 0,
    averageWeeklyRevenue: 0,
    averageMonthlyRevenue: 0,
    dailyOrders: 0,
    weeklyOrders: 0,
    monthlyOrders: 0,
    averageDailyOrders: 0,
    averageWeeklyOrders: 0,
    averageMonthlyOrders: 0,
  });

  useEffect(() => {
    const fetchStatistics = async () => {
      const commandesSnapshot = await getDocs(collection(db, 'total_commande'));
      const commandes = commandesSnapshot.docs.map(doc => doc.data());
  
      // Calcul des statistiques
      const dishPercentages = calculateDishPercentages(commandes);
      const hourlyOrders = calculateHourlyOrders(commandes);
      const dailyRevenue = calculateDailyRevenue(commandes);
      const weeklyRevenue = calculateWeeklyRevenue(commandes);
      const monthlyRevenue = calculateMonthlyRevenue(commandes);
      const averageDailyRevenue = calculateAverageDailyRevenue(commandes);
      const averageWeeklyRevenue = calculateAverageWeeklyRevenue(commandes);
      const averageMonthlyRevenue = calculateAverageMonthlyRevenue(commandes);
      const dailyOrders = calculateDailyOrders(commandes);
      const weeklyOrders = calculateWeeklyOrders(commandes);
      const monthlyOrders = calculateMonthlyOrders(commandes);
      const averageDailyOrders = calculateAverageDailyOrders(commandes);
      const averageWeeklyOrders = calculateAverageWeeklyOrders(commandes);
      const averageMonthlyOrders = calculateAverageMonthlyOrders(commandes);
  
      setStatistics({
        dishPercentages,
        hourlyOrders,
        dailyRevenue,
        weeklyRevenue,
        monthlyRevenue,
        averageDailyRevenue,
        averageWeeklyRevenue,
        averageMonthlyRevenue,
        dailyOrders,
        weeklyOrders,
        monthlyOrders,
        averageDailyOrders,
        averageWeeklyOrders,
        averageMonthlyOrders,
      });
    };
  
    fetchStatistics();
  
    // Fonction pour réinitialiser les données à intervalles réguliers
    const intervalId = setInterval(() => {
      fetchStatistics();
    }, 24 * 60 * 60 * 1000); // Rafraîchir tous les jours (en millisecondes)
  
    return () => clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage du composant
  }, []); // Le tableau vide [] signifie que cela ne se déclenchera qu'une fois au chargement initial
  
  useEffect(() => {
    const fetchStatistics = async () => {
      // même logique que précédemment...
    };
  
    // Rafraîchir les statistiques toutes les 24 heures
    const dailyIntervalId = setInterval(() => {
      fetchStatistics();
    }, 24 * 60 * 60 * 1000); // Rafraîchissement quotidien
  
    // Rafraîchir les statistiques toutes les semaines
    const weeklyIntervalId = setInterval(() => {
      fetchStatistics();
    }, 7 * 24 * 60 * 60 * 1000); // Rafraîchissement hebdomadaire
  
    // Rafraîchir les statistiques chaque mois
    const monthlyIntervalId = setInterval(() => {
      fetchStatistics();
    }, 30 * 24 * 60 * 60 * 1000); // Rafraîchissement mensuel
  
    // Nettoyer tous les intervalles au démontage du composant
    return () => {
      clearInterval(dailyIntervalId);
      clearInterval(weeklyIntervalId);
      clearInterval(monthlyIntervalId);
    };
  }, []);  

  // Calculs pour les pourcentages de plats et commandes par heure
  const calculateDishPercentages = (commandes) => {
    const dishCounts = {};
    const totalDishes = commandes.reduce((total, commande) => {
      return total + commande.items.reduce((subTotal, item) => {
        dishCounts[item.nom] = (dishCounts[item.nom] || 0) + item.quantity;
        return subTotal + item.quantity;
      }, 0);
    }, 0);

    const percentages = {};
    for (const [dish, count] of Object.entries(dishCounts)) {
      percentages[dish] = ((count / totalDishes) * 100).toFixed(2);
    }
    return percentages;
  };

  const calculateHourlyOrders = (commandes) => {
    const hourlyCounts = Array(14).fill(0); // De 10h à 23h inclus (14 heures)
  
    commandes.forEach(commande => {
      const orderHour = new Date(commande.date.toDate()).getHours();
      if (orderHour >= 10 && orderHour <= 23) {
        hourlyCounts[orderHour - 10] += 1; // Décale pour correspondre à l'index 0
      }
    });
  
    const totalOrders = commandes.length;
    return hourlyCounts.map(count => ((count / totalOrders) * 100).toFixed(2));
  };
  
  const calculateDailyRevenue = (commandes) => {
    const today = new Date();
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      if (
        orderDate.getDate() === today.getDate() &&
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear()
      ) {
        return total + commande.total;
      }
      return total;
    }, 0);
  };

  const calculateWeeklyRevenue = (commandes) => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      if (orderDate >= startOfWeek && orderDate <= today) {
        return total + commande.total;
      }
      return total;
    }, 0);
  };

  const calculateMonthlyRevenue = (commandes) => {
    const today = new Date();
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      if (
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear()
      ) {
        return total + commande.total;
      }
      return total;
    }, 0);
  };

  const calculateAverageDailyRevenue = (commandes) => {
    const dailyRevenues = {};

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const day = date.getDate();
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;

      if (!dailyRevenues[monthYear]) {
        dailyRevenues[monthYear] = {};
      }

      if (!dailyRevenues[monthYear][day]) {
        dailyRevenues[monthYear][day] = 0;
      }

      dailyRevenues[monthYear][day] += commande.total;
    });

    const totalDays = Object.values(dailyRevenues).reduce((sum, month) => sum + Object.keys(month).length, 0);
    const totalRevenue = Object.values(dailyRevenues).reduce((sum, month) => {
      return sum + Object.values(month).reduce((monthSum, daily) => monthSum + daily, 0);
    }, 0);

    return totalDays > 0 ? (totalRevenue / totalDays).toFixed(2) : 0;
  };

  const calculateAverageWeeklyRevenue = (commandes) => {
    const weeklyRevenues = {};
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const week = Math.ceil((((date - yearStart) / 86400000) + yearStart.getDay() + 1) / 7);
      const year = date.getFullYear();
      const yearWeek = `${year}-${week}`;

      if (!weeklyRevenues[yearWeek]) {
        weeklyRevenues[yearWeek] = 0;
      }

      weeklyRevenues[yearWeek] += commande.total;
    });

    const totalWeeks = Object.keys(weeklyRevenues).length;
    const totalRevenue = Object.values(weeklyRevenues).reduce((sum, revenue) => sum + revenue, 0);

    return totalWeeks > 0 ? (totalRevenue / totalWeeks).toFixed(2) : 0;
  };

  const calculateAverageMonthlyRevenue = (commandes) => {
    const monthlyRevenues = {};

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;

      if (!monthlyRevenues[monthYear]) {
        monthlyRevenues[monthYear] = 0;
      }

      monthlyRevenues[monthYear] += commande.total;
    });

    const totalMonths = Object.keys(monthlyRevenues).length;
    const totalRevenue = Object.values(monthlyRevenues).reduce((sum, revenue) => sum + revenue, 0);

    return totalMonths > 0 ? (totalRevenue / totalMonths).toFixed(2) : 0;
  };

  const calculateDailyOrders = (commandes) => {
    const today = new Date();
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      return orderDate.getDate() === today.getDate() &&
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear()
        ? total + 1
        : total;
    }, 0);
  };

  const calculateWeeklyOrders = (commandes) => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      return orderDate >= startOfWeek && orderDate <= today
        ? total + 1
        : total;
    }, 0);
  };

  const calculateMonthlyOrders = (commandes) => {
    const today = new Date();
    return commandes.reduce((total, commande) => {
      const orderDate = new Date(commande.date.toDate());
      if (
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear()
      ) {
        return total + 1;
      }
      return total;
    }, 0);
  };

  const calculateAverageDailyOrders = (commandes) => {
    const dailyOrders = {};

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const day = date.getDate();
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;

      if (!dailyOrders[monthYear]) {
        dailyOrders[monthYear] = {};
      }

      if (!dailyOrders[monthYear][day]) {
        dailyOrders[monthYear][day] = 0;
      }

      dailyOrders[monthYear][day] += 1;
    });

    const totalDays = Object.values(dailyOrders).reduce((sum, month) => sum + Object.keys(month).length, 0);
    const totalOrders = Object.values(dailyOrders).reduce((sum, month) => {
      return sum + Object.values(month).reduce((monthSum, daily) => monthSum + daily, 0);
    }, 0);

    return totalDays > 0 ? (totalOrders / totalDays).toFixed(2) : 0;
  };

  const calculateAverageWeeklyOrders = (commandes) => {
    const weeklyOrders = {};
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const week = Math.ceil((((date - yearStart) / 86400000) + yearStart.getDay() + 1) / 7);
      const year = date.getFullYear();
      const yearWeek = `${year}-${week}`;

      if (!weeklyOrders[yearWeek]) {
        weeklyOrders[yearWeek] = 0;
      }

      weeklyOrders[yearWeek] += 1;
    });

    const totalWeeks = Object.keys(weeklyOrders).length;
    const totalOrders = Object.values(weeklyOrders).reduce((sum, orders) => sum + orders, 0);

    return totalWeeks > 0 ? (totalOrders / totalWeeks).toFixed(2) : 0;
  };

  const calculateAverageMonthlyOrders = (commandes) => {
    const monthlyOrders = {};

    commandes.forEach(commande => {
      const date = commande.date.toDate();
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;

      if (!monthlyOrders[monthYear]) {
        monthlyOrders[monthYear] = 0;
      }

      monthlyOrders[monthYear] += 1;
    });

    const totalMonths = Object.keys(monthlyOrders).length;
    const totalOrders = Object.values(monthlyOrders).reduce((sum, orders) => sum + orders, 0);

    return totalMonths > 0 ? (totalOrders / totalMonths).toFixed(2) : 0;
  };

  // Données pour les graphiques
  const dishData = {
    labels: Object.keys(statistics.dishPercentages),
    datasets: [
      {
        data: Object.values(statistics.dishPercentages),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  const hourlyData = {
    labels: Array.from({ length: 13 }, (_, i) => `${i + 11}h`),
    datasets: [
      {
        data: statistics.hourlyOrders,
        borderColor: '#36A2EB',
        fill: false,
        tension: 0.1,
      },
    ],
  };
  
  const hourlyOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false, // Masquer la légende si elle rend flou
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        grid: { display: true },
      },
    },
  };

  const dishOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const [chartType, setChartType] = useState('pie');
  
  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  return (
    <div className="statistics-canvas">
      <h1>Section Statistiques</h1>
      
      <h2>Pourcentage de chaque plat commandé</h2>
      <div className='radio-container'>
        <input
          type="radio"
          id="pie"
          value="pie"
          checked={chartType === 'pie'}
          onChange={handleChartTypeChange}
        />
        <label htmlFor="pie">Camembert</label>
        
        <input
          type="radio"
          id="bar"
          value="bar"
          checked={chartType === 'bar'}
          onChange={handleChartTypeChange}
        />
        <label htmlFor="bar">Bâton</label>
      </div>
      <div className="chart-container">
        {chartType === 'pie' ? (
          <Pie data={dishData} options={dishOptions} />
        ) : (
          <Bar data={dishData} options={dishOptions} />
        )}
      </div>

      <h2>Pourcentage de commande par heure (de 11h à 23h)</h2>
      <div className="chart-container">
        <Line data={hourlyData} options={hourlyOptions} />
      </div>

      <div className="statistics-section">
        <div className="stat-block revenue-section">
          <h2 className='borderRevenue'>Revenus</h2>
          <p>Revenus quotidiens : <span className="highlight">{statistics.dailyRevenue} €</span></p>
          <p>Revenus hebdomadaires : <span className="highlight">{statistics.weeklyRevenue} €</span></p>
          <p>Revenus mensuels : <span className="highlight">{statistics.monthlyRevenue} €</span></p>
          <p>Moyenne quotidienne : <span className="highlight">{statistics.averageDailyRevenue} €</span></p>
          <p>Moyenne hebdomadaire : <span className="highlight">{statistics.averageWeeklyRevenue} €</span></p>
          <p>Moyenne mensuelle : <span className="highlight">{statistics.averageMonthlyRevenue} €</span></p>
        </div>

        <div className="stat-block">
          <h2 className='borderRevenue'>Commandes</h2>
          <p>Total de commandes aujourd'hui : <span className="highlight">{statistics.dailyOrders}</span></p>
          <p>Total de commandes cette semaine : <span className="highlight">{statistics.weeklyOrders}</span></p>
          <p>Total de commandes ce mois : <span className="highlight">{statistics.monthlyOrders}</span></p>
          <p>Moyenne quotidienne : <span className="highlight">{statistics.averageDailyOrders}</span></p>
          <p>Moyenne hebdomadaire : <span className="highlight">{statistics.averageWeeklyOrders}</span></p>
          <p>Moyenne mensuelle : <span className="highlight">{statistics.averageMonthlyOrders}</span></p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;